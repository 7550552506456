import React, { Component } from "react";
import {
  deleteProduct,
  getAllProductCategory,
  getAllProducts
} from "../../functions/postFunctions";
import { LinkButton, CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import SearchableDropdown from "../../components/Form/Dropdown/SearchableDropdown";
import SortProducts from "../../components/SortProducts/SortProducts";

class ProductsPage extends Component {
  state = {
    theads: ["Cover", "Naziv", "Stanje", "Jedinica mjere", "Akcije"],
    products: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
    sortProductsModal: false,
    sortableCategories: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Proizvodi");

    let categories = [];
    let sortableCategories = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        categories.push({ value: e.id, label: e.name });
        if (e.children.length > 0) {
          handleRecursion(e.children);
        } else {
          sortableCategories.push({ value: e.id, label: e.name });
        }
      });
    };

    getAllProductCategory().then(res => {
      if (res.success) {
        handleRecursion(res.categories);
        categories.unshift({ value: 0, label: "Svi proizvodi" });
        const currentCategory = categories.find(e => e.value === 0);
        this.setState({
          categoryList: categories,
          currentCategory,
          sortableCategories
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
  }

  handleDeletePage = id => {
    const { products } = this.state;
    const newProducts = products.filter(e => e.id !== id);
    deleteProduct(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ products: newProducts });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllProducts(20, 0, currentCategory.value).then(res => {
      if (res.success) {
        this.setState({
          products: res.products,
          page: 1,
          removeLoadMore: res.products.length < 20
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(20, page * 20, currentCategory.value).then(res => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: [...products, ...res.products],
          page: page + 1,
          removeLoadMore: res.products.length < 20
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  toggleModal = () => {
    this.setState(
      ({ sortProductsModal }) => ({
        sortProductsModal: !sortProductsModal
      }),
      () => {
        if (!this.state.sortProductsModal) {
          this.loadNews();
        }
      }
    );
  };

  redirectAddProduct = () => {
    this.props.history.push("dodaj-proizvod");
  };
  render() {
    const {
      theads,
      products,
      categoryList,
      currentCategory,
      sortProductsModal,
      sortableCategories
    } = this.state;
    return (
      <div>
        <SortProducts
          hideModal={this.toggleModal}
          visible={sortProductsModal}
          sortableCategories={sortableCategories}
          token={this.props.token}
        />
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdown
                data={categoryList}
                placeholder="Kategorija"
                name="currentCategory"
                handleChange={this.handleInput}
                value={currentCategory}
              />
            </div>
            <div className="col-md-9">
              <CustomButton
                onClick={this.redirectAddProduct}
                className="mr-20 green"
              >
                Dodaj proizvod
              </CustomButton>
              <CustomButton onClick={this.toggleModal}>
                Sortiraj proizvode
              </CustomButton>
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {products.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <img
                    src={e.cover}
                    alt={e.slug}
                    style={{ maxHeight: "50px" }}
                  />
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.name}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.amount || 0}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">
                    {e.unit === 0 ? "kg" : "Komad"}
                  </span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <LinkButton
                    href={`izmjeni-proizvod/${e.id}`}
                    className="mr-20"
                  >
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
        {!this.state.removeLoadMore ? (
          <div className="wrapper mt-40 d-flex justify-content-center">
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ProductsPage;
