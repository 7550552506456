import React from "react";

const Logo = props => {
  return (
      <img
          src="https://www.montefish.me/images/logo-plavi.png"
          alt=""/>
  );
};

export default Logo;
